.App {
  background-color: black;
}

.header {
  text-align: center;
  padding: 10px;
  background-color: #944123;
  border-radius: 10px;
  position: fixed;
  top: 0%;
  color: #fff;
  font-family: serif;
  font-style: italic;
  left: 50%;
  transform: translate(-50%);
}

.grid-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(2, 50vh);
  gap: 5px;
}

.grid-item {
  background-size: cover;
  background-position: center;
}

.one {
  background-image: url('../assets/Sid&Guy_Engagement.jpg');
}

.two {
  background-image: url('../assets/Guy&Sid_Couples_Drink.jpg');
}

.three {
  background-image: url('../assets/Guy&SidEngagementPhotography.jpg');
}

.four {
  background-image: url('../assets/Sid&Guy&Mots.jpg');
}

.five {
  background-image: url('../assets/Sid&Guy_Playful.jpg');
}

.six {
  background-image: url('../assets/Sid&Guy_Backpacking.jpg');
}

.directions-wrapper {
  position: absolute;
  top: 31%;
  left: 5%;
  width: 400px;
  padding: 35px;
  color: #fff;
  background: rgba(0, 0, 0, .5);
  box-sizing: border-box;
  box-shadow: 0 15px 25px rgba(0, 0, 0, .6);
  border-radius: 10px;
}

.invite {
  border-bottom: solid #fff;
  border-radius: 2px;
  padding-bottom: 10px;
}

.donate-wrapper {
  position: absolute;
  top: 30%;
  right: 5%;
  width: 400px;
  padding: 25px;
  color: #fff;
  background: rgba(0, 0, 0, .5);
  box-sizing: border-box;
  box-shadow: 0 15px 25px rgba(0, 0, 0, .6);
  border-radius: 10px;
}

a {
  outline-color: transparent;
  padding: 2px 1px 0;
}

a:link {
  color: aqua;
}

a:visited {
  color: aqua;
}

a:focus,
a:hover {
  border-bottom: 1px solid;
}

a:active {
  color: red;
}

.donate-options-wrapper {
  display: flex;
}

.donate-options-wrapper a {
  margin-right: 10px;
  /* Adjust spacing between images */
}

.donate-options-wrapper a:last-child {
  margin-right: 0;
  /* Remove margin from last image to prevent extra spacing */
}

.donate-image {
  width: 150px;
  /* Adjust width of the images as needed */
  height: auto;
  /* Maintain aspect ratio */
}

.sids-venmo {
  width: 80%;
}

.scroll-for-more {
  display: none;
}

@media screen and (min-width: 1024px) and (max-width: 1440px) {
  .donate-wrapper {
    top: 25%;
    padding: 20px;
  }
}


@media only screen and (min-width: 401px) and (max-width: 1000px) {
  .scroll-for-more {
    display: flex;
    color: white;
    margin-left: 30%;
    margin-top: 15%;
  }

  body {
    background-color: black;
  }

  .header {
    background-color: #944123;
    border-radius: 10px;
    position: absolute;
    top: 0%;
    color: #fff;
    font-family: serif;
    font-style: italic;
    width: 70%;
    /* left: 50%;
    transform: translate(-50%); */
  }

  .grid-container {
    position: absolute;
    z-index: -1;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  .grid-item {
    width: 100%;
    height: 0;
    padding-top: calc(100% / 2);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
  }

  .one {
    height: 35%;
    margin-bottom: 88%;
    margin-top: 45%;
  }

  .two {
    height: 25%;
    margin-bottom: 165%;

  }

  .three {
    height: 40%;
    margin-bottom: 95%;
  }

  .four {
    width: 100%;
    height: 15%;
  }

  .five {
    display: none;
  }

  .six {
    display: none;
  }

  .directions-wrapper {
    width: 100%;
    position: absolute;
    top: 90%;
    left: 0;
    padding: 20px;
    color: #fff;
    background: rgba(0, 0, 0, .5);
    box-sizing: border-box;
    box-shadow: 0 15px 25px rgba(0, 0, 0, .6);
    border-radius: 10px;
  }

  .donate-wrapper {
    top: 308%;
    width: 100%;
    padding: 10px;
    color: #fff;
    background: rgba(0, 0, 0, .5);
    box-sizing: border-box;
    box-shadow: 0 15px 25px rgba(0, 0, 0, .6);
    border-radius: 10px;
    left: 50%;
    transform: translate(-50%);
  }

}

@media only screen and (max-width: 400px) {
  .scroll-for-more {
    display: flex;
    color: white;
    margin-left: 30%;
    margin-top: 15%;
  }

  body {
    background-color: black;
  }

  .header {
    background-color: #944123;
    border-radius: 10px;
    position: absolute;
    top: 0%;
    color: #fff;
    font-family: serif;
    font-style: italic;
    width: 90%;
    /* left: 50%;
    transform: translate(-50%); */
  }

  .grid-container {
    position: absolute;
    z-index: -1;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  .grid-item {
    width: 100%;
    height: 0;
    padding-top: calc(100% / 2);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
  }

  .one {
    height: 35%;
    margin-bottom: 88%;
    margin-top: 45%;
  }

  .two {
    height: 25%;
    margin-bottom: 165%;

  }

  .three {
    height: 40%;
    margin-bottom: 100%;

  }

  .four {
    width: 100%;
    height: 15%;
  }

  .five {
    display: none;
  }

  .six {
    display: none;
  }

  .directions-wrapper {
    width: 100%;
    position: absolute;
    top: 92%;
    left: 0;
    padding: 20px;
    color: #fff;
    background: rgba(0, 0, 0, .5);
    box-sizing: border-box;
    box-shadow: 0 15px 25px rgba(0, 0, 0, .6);
    border-radius: 10px;
  }

  .donate-wrapper {
    top: 350%;
    width: 100%;
    padding: 10px;
    color: #fff;
    background: rgba(0, 0, 0, .5);
    box-sizing: border-box;
    box-shadow: 0 15px 25px rgba(0, 0, 0, .6);
    border-radius: 10px;
    left: 50%;
    transform: translate(-50%);
  }

}