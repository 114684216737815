.RSVP-Form {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 400px;
    padding: 0px 40px 40px 40px;
    color: #fff;
    background: rgba(0, 0, 0, .5);
    box-sizing: border-box;
    box-shadow: 0 15px 25px rgba(0, 0, 0, .6);
    border-radius: 10px;
}

.rsvp-h3 {
    position: relative;
    left: 40%;
}

.RSVP-Form input {
    width: 100%;
    padding: 10px 0;
    font-size: 16px;
    color: #fff;
    margin-bottom: 30px;
    border: none;
    border-bottom: 1px solid #fff;
    outline: none;
    background: transparent;
}

.RSVP-Form-card {
    border-style: solid;
    padding: 20px;
    margin-bottom: 5px;
    border-radius: 10px;
}

select {
    border-radius: 5px;
    font-size: medium;
    margin: 10px 10px 10px 0px;
}

.remove-guest {
    border: 1px solid #d5d9d9;
    border-radius: 8px;
    padding: 6px;
}

.add-guest,
.submit-button {
    background-color: #fff;
    border: 1px solid #d5d9d9;
    border-radius: 8px;
    box-shadow: rgba(213, 217, 217, .5) 0 2px 5px 0;
    box-sizing: border-box;
    color: #0f1111;
    cursor: pointer;
    display: inline-block;
    font-size: 13px;
    line-height: 25px;
    padding: 0 10px 0 11px;
    text-align: center;
    text-decoration: none;
    touch-action: manipulation;
    width: 100px;
}

.add-guest,
.submit-button:hover {
    background-color: #f7fafa;
}

.add-guest,
.submit-button:focus {
    border-color: #008296;
    box-shadow: rgba(213, 217, 217, .5) 0 2px 5px 0;
    outline: 0;
}

@media only screen and (max-width: 401px) {

    .RSVP-Form {
        top: 240%;
        position: absolute;
        padding: 0px 30px 30px 30px;
        color: #fff;
        background: rgba(0, 0, 0, .5);
        box-sizing: border-box;
        box-shadow: 0 15px 25px rgba(0, 0, 0, .6);
        border-radius: 10px;
    }
}

@media only screen and (max-width: 400px) {
    .RSVP-Form {
        top: 236%;
        position: absolute;
        padding: 0px 30px 30px 30px;
        color: #fff;
        background: rgba(0, 0, 0, .5);
        box-sizing: border-box;
        box-shadow: 0 15px 25px rgba(0, 0, 0, .6);
        border-radius: 10px;
        width: 100%;
    }

    .rsvp-h3 {
        position: relative;
        left: 40%;
        width: 50%;
    }
}